import React from "react"
import * as css from "@css/projects/p-news-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
import { TransitionLink } from "../atoms/Link"

function NewsList({ data }) {
    // data = [
    //     { node: {
    //         date: '2022.1.10',
    //         title: '[2/20(水)受注受付終了] FC会員限定グッズ福袋',
    //         databaseId: '1'
    //     }},
    //     { node: {
    //         date: '2022.1.10',
    //         title: '[2/20(水)受注受付終了] FC会員限定グッズ福袋',
    //         databaseId: '1'
    //     }},
    //     { node: {
    //         date: '2022.1.10',
    //         title: '[2/20(水)受注受付終了] FC会員限定グッズ福袋',
    //         databaseId: '1'
    //     }}
    // ]
    return (
        <div className={css.pNewsList}>
            {data.length === 0 ?
                <div className={css.aEmpty}>
                    <h5 className={css.aTitle}>Empty</h5>
                    <p className={css.aText}>まだ投稿がありません。</p>
                </div>
                :
                <ul>
                    {data.map(({ node }, i) => {
                        let { date, title, uri, acf_news, databaseId } = node
                        // let {category} = acf_news
                        console.log("===>", uri)
                        return (
                            <li key={i}>
                                <TransitionLink className={css.aInner} to={`/news/${databaseId}/`}>
                                    <p className={css.aDate}>{date}</p>
                                    <p className={css.aTitle} dangerouslySetInnerHTML={{ __html: title }}/>
                                </TransitionLink>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}

export { NewsList }
import React from "react"
import { navigate } from 'gatsby-link'
import {useRecoilState} from "recoil"
import { pageSortState } from "@status/PageState"

import * as css from "@css/projects/p-list-controller.module.styl"
import Select from 'react-select'
import SelectStyles from "@utility/SelectStyles"

export default function ListController({label, options, sortType, current, parent})
{
    const [selected, setSelected] = React.useState(false)
    const [state, setState] = React.useState({})
    const [pageSort, setPageSort] = useRecoilState(pageSortState)
    const opts = options || [
        { value: 'all', label: 'すべて' },
        { value: 2021, label: '2021' },
        { value: 2020, label: '2020' },
    ]
    let defaultValue = opts[0]
    if( current && current !== "" ){
        defaultValue = {
            value: current,
            label: current,
        }
    }
    React.useEffect(()=>{
        setSelected(false)
        setState({
            ...pageSort,
            [sortType]: current
        })
    },[])
    React.useEffect(()=>{
        if( state === {} ) { return }
        setPageSort(state)
    },[state])

    React.useEffect(()=>{
        if(selected === false){ return }
        console.log(pageSort)
        let { year, category } = pageSort
        if( year === "" && category === ""){
        } else {
            if( year && !category ){
                let url = `/${parent}/`
                if (year !== "all") {
                    url += `${year}/`
                }
                navigate(url)
            }
            if( !year && category ){
                let url = `/${parent}/`
                if (category !== "all") {
                    url += `${category.toLowerCase()}/`
                }
                navigate(url)
            }
            if( year && category ){
                let url = `/${parent}/`
                if( year !== "all" ){
                    url += `${year}/`
                }
                if (category !== "all") {
                    url += `${category.toLowerCase()}/`
                }
                navigate(url)
            }
        }
    },[pageSort])

    return (
        <div className={css.pListController}>
            <div className={css.pListController__inner}>
                <div className={css.aLabel}>{label}</div>
                <Select
                    className={css.aSelect}
                    styles={SelectStyles}
                    defaultValue={defaultValue}
                    options={opts}
                    onChange={ ({value}) => {
                        setState({
                            ...pageSort,
                            [sortType]: value
                        })
                        setSelected(true)
                    }}
                />
            </div>
        </div>
    )
}

const bgColor = '#fff'
const bgFocused = '#F7F7F7'
export default {
    indicatorSeparator: (provided, state) => ({
        ...provided,
        background: "none",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#000000',
        opacity: 1.0,
        
    }),
    option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'red' : 'blue',
        background: state.isSelected ?
            "#000000"
            :
            state.isFocused ? bgFocused : bgColor,
    }),
    control: (base, state) => ({
        ...base,
        background: "none",
        borderRadius: "none",
        // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // borderColor: state.isFocused ? "yellow" : "green",
        borderColor: bgColor,
        color: state.isFocused ? "yellow" : "green",
        // boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // borderColor: state.isFocused ? "red" : "blue"
        }
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        background: bgColor,
    }),
    menuList: base => ({
        ...base,
        padding: 0,
        background: bgColor,
        border: '1px solid #fff'
    })
}
import * as React from "react"
import { graphql, navigate} from "gatsby"
// import IndexBlcok from "@blocks/Index"
import Seo from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import * as css from "@css/layouts/l-news.module.styl"
import { MarqueePageTitle } from "@projects/MarqueePageTitle"
import { NewsList } from "@projects/NewsList"
import ListController from "@projects/ListController"
//カテゴリページ
function NewsListPage({data,pageContext})
{
    console.log(data, pageContext)
    const { news, categorys, ogpImage} = data
    let yearsOptions = pageContext.years.map((val)=>{
        return {
            value: val,
            label: val,
        }
    })
    yearsOptions.unshift({
        value: 'all',
        label: 'すべて',
    })
    return(
        <>
            <Seo/>
            <Spacer.Header/>
            <main className={css.lNews}>
                <MarqueePageTitle title="News"/>
                <div className={css.aInner}>
                    <ListController label="期間"
                        parent="news"
                        current={pageContext.year}
                        options={yearsOptions}
                        sortType="year"
                    />
                    <NewsList data={news.edges}/>
                </div>
            </main>
        </>
    )
}

export const pageQuery = graphql`
    query NewsList(
        $periodStartDate: Date!,
        $periodEndDate: Date!
    ){
        news: allWpNews(
            filter: {
                date: { gte: $periodStartDate, lt: $periodEndDate }
            }
            limit: 9999
            sort: { fields: [date], order: DESC }) {
            edges {
                node {
                    ...News
                }
            }
        }
    }
`
export default NewsListPage

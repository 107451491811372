// extracted by mini-css-extract-plugin
export var aArrow1 = "lc";
export var aArrow2 = "mc";
export var aButton1 = "bc";
export var aButton2 = "cc";
export var aButton3 = "dc";
export var aButton4 = "ec";
export var aButton5 = "fc";
export var aButtonHeaderLogin = "gc";
export var aButtonHeaderRegister = "hc";
export var aButtonHeaderSpLogin = "kc";
export var aButtonLogin = "jc";
export var aButtonRegister = "ic";
export var aDate = "ac";
export var aEmpty = "Xb";
export var aInner = "_b";
export var aText = "nc";
export var aTitle = "Yb";
export var animationMarquee = "oc";
export var pNewsList = "Zb";
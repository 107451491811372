import { atom } from 'recoil'
// import { recoilPersist } from 'recoil-persist'

// const { persistAtom } = recoilPersist()

export const pageSortState = atom({
    key: 'pageSort',
    default: {
        year: '',
        category: '',
    },
})